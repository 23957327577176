import Checkbox from "@/Components/atoms/Checkbox";
import GuestLayout from "@/Components/templates/GuestLayout";
import { Head, Link } from "@inertiajs/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@/Components/atoms/Button";
import { useForm } from "react-hook-form";
import { accountLogin } from "@/api/auth/api";
import TextForm from "@/Components/molecules/TextForm";
import ErrorMessage from "./common/ErrorMessage";

export default function Login({
  errors,
  status,
  // canResetPassword,
}: {
  errors: AuthErrors;
  status?: string;
  canResetPassword: boolean;
}) {
  const validation = yup.object({
    remember: yup.boolean().required(),
    email: yup.string().required(),
    password: yup.string().required(),
  });

  const { register, handleSubmit } = useForm<AccountLogin>({
    resolver: yupResolver(validation),
    mode: "onSubmit",
    defaultValues: {
      remember: false,
    },
  });

  const onSubmit = handleSubmit((data) => {
    accountLogin(data);
  });

  return (
    <GuestLayout>
      <Head title="Log in" />

      {status && (
        <div className="mb-4 font-medium text-sm text-green-600">{status}</div>
      )}

      <form onSubmit={onSubmit}>
        <div>
          <TextForm
            label="email"
            type="email"
            autoComplete="username"
            required
            {...register("email")}
          />
        </div>

        <div className="mt-4">
          <TextForm
            label="password"
            type="password"
            autoComplete="new-password"
            required
            {...register("password")}
          />
        </div>

        <div className="block mt-4">
          <label className="flex items-center">
            <Checkbox {...register("remember")} />
            <span className="mt-1 ms-2 text-sm text-gray-600">
              ログイン状態を維持する
            </span>
          </label>
        </div>

        <div className="flex items-center justify-center mt-4 mr-2">
          <Button className="ms-4" disabled={false}>
            ログイン
          </Button>
        </div>
        <ErrorMessage errors={errors} />

        <div className="mt-4">
          <Link
            href={route("register")}
            className="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            アカウント作成がまだの方はこちら
          </Link>
        </div>
        <div className="mt-1">
          <Link
            href={route("password.request")}
            className="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            パスワードを忘れた方はこちら
          </Link>
        </div>
      </form>
    </GuestLayout>
  );
}
